import { useEffect, useState } from 'react'
import Loader from '../../../shared/Loader';
import {GiCancel} from "react-icons/gi";
import { toast } from 'react-toastify';
import { BusinessService } from 'services/business.service';


const BusinessesModal = ({setShowModal, values, setValues}) => {
  const [allBusinesses, setAllBusinesses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [selected, setSelected] = useState(values.contacts || []);

  useEffect(() => {
    setLoading(true)
    getBusinesses("none"); // "none" is a value so that "" can be used with the filter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getBusinesses = (val) => {
    BusinessService.getBusinessByStatus(val==="none"||val==="all"?"":val)
      .then(response => response.data)
      .then((res) => {
        setLoading(false)
        if(val==="none"){
          setAllBusinesses(res?.data);
        } else {
          setSelected(res.data)
        }
      })
      .catch((err) => {
        setLoading(false)
        setFilter("none")
        toast.error("Unable to get businesses!")
      });
  }

  const handleItemSelect = (item) => {
    const objectExists = selected.find(e => e._id === item._id);
    if (objectExists) {
      const newArray = selected.filter( x => {
        return x._id !== item._id;
      });
      setSelected(newArray)
    } else {
      setSelected([
        ...selected,
        item
      ])
    }
  }

  const selectMultiple = (checked, item) => {
    if(checked){
      setLoading(true)
      setFilter(item);
      getBusinesses(item);
    } else {
      setFilter("none");
      setSelected([])
    }
  }

  const handleSubmit = () => {
    setValues({
      ...values,
      contacts: selected
    })
    setShowModal(false)
  }

  return (
    <div className="myModal">
      {<div className="myModal_blurBg" ></div>}
      <div className= {"myModal_main"}>
        <div className="myModal_main-biz !text-left pt-0 px-[1em] !pb-[20px]">
          <GiCancel className='absolute text-black w-7 top-4 right-3 cursor-pointer' onClick={()=>setShowModal(false)} /> 
          <h4 className='font-semibold mb-[1.5rem]'>Business Recipients</h4>
          {
            loading ? 
            <Loader /> 
            :
            <>
              <div className='border-b border-[#505050] font-bold grid grid-cols-3'>
                {
                  ["all", "active", "inactive", "approved", "pending", "declined"].map(item => (
                    <div className='flex items-center' key={item}>
                      <input 
                        type="checkbox" 
                        className='w-[25px] -mt-[7px] mr-[2px]'
                        checked={filter===item}
                        onChange={(e)=>{
                            selectMultiple(e.target.checked, item)
                        }} 
                      />
                      <p className='mb-[0.7rem] capitalize'>
                        {
                          item==="all" ? "Select All"
                          : item
                        }
                      </p>
                    </div>
                  ))
                }
              </div>
              <div className='myModal_main-biz-flex h-[320px] overflow-y-scroll'>
                {
                  allBusinesses?.map((item, idx) => (
                    <label className='flex mr-[5px] items-center' key={idx}>
                      <input 
                        type="checkbox"
                        className='w-[25px] -mt-[7px] mr-[7px]'
                        checked={
                          selected.find(e => e._id === item._id)
                        }
                        onChange={()=>handleItemSelect(item)} 
                      />
                      <p className='font-semibold mb-[0.6em]'>{item?.businessName}</p>
                    </label>
                  ))
                }
              </div>

              <div className="myModal_main-delete-bottom pt-[20px] pb-0 px-0">
                <button 
                  className="publish !bg-[#0046E6] border-0" 
                  onClick={handleSubmit}
                >
                  Done
                </button>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default BusinessesModal