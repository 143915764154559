import Button from "components/shared/Button";
import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
} from "recharts";
import { currencyFormatter, formatBigNumber } from "utils/helpers";


export default function CustomBarChart({ 
    title, subtitle, data, yAxisName,
    removeFirst=false,
    currencyFormat=false,
    hideMonthButton=false,
    months=[],
 }) {

    const formattedData = data?.slice(removeFirst?1:0)?.map((item, idx)=>({
        name: months[idx]?.substring(0, 3),
        [`${yAxisName}`]: item ?? 0,
    }))
    
    return (
        <div className="bg-white rounded-lg p-[40px] shadow-[0px_1.21px_2.41px_0px_#1018280F,0px_1.21px_3.62px_0px_#1018281A] border-[1px] border-[#EAECF0]">
            <h3 className="font-medium text-[24px] leading-[27px]">{title}</h3>
            { !!subtitle && <p>{subtitle}</p> }
            {
                !hideMonthButton &&
                <Button
                    text="12 months"
                    type="button"
                    className="bg-[#CCDCFF] px-3 py-2.5 mt-2 mb-[20px] !text-base !text-ee-primary !rounded-md"
                />
            }
            <div className="mt-7">
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                        data={formattedData}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                        padding={{
                            top: 40,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid
                            strokeDasharray="3 3"
                            horizontal={true}
                            vertical={false}
                        />
                        <XAxis dataKey="name" tick="#818181" />
                        <YAxis  tick="#818181" tickFormatter={(value)=>`${currencyFormat ? currencyFormatter(value, "NGN", 0, true):value}`} />
                        <Tooltip contentStyle={{backgroundColor:"#fff", color: '#0046E6'}} 
                            itemStyle={{color:"#0046E6"}} 
                            formatter={(value) => 
                                currencyFormat ?
                                currencyFormatter(value||0, "NGN", 2, true)
                                :
                                formatBigNumber(value||0)
                            } 
                        />
                        <Bar 
                            dataKey={yAxisName}
                            barSize={40} 
                            fill="#0046E6"
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}