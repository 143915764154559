import ReactPaginate from 'react-paginate';
import { useState } from 'react';
import { useEffect } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useSearchParams } from 'react-router-dom';


export default function Pagination ({
    data, 
    setDisplayData,
    showDownload=false,
    onDownloadClick,
    backendPaginated, //optional
    totalPages, //optional
}) {
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0)
    const itemsPerPage = 10;
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") ?? 1

    useEffect(()=>{
        const endOffset = itemOffset + itemsPerPage;
        setDisplayData(data?.slice(itemOffset, endOffset) || []);
        if(backendPaginated){
            setPageCount(totalPages);
        } else {
            setPageCount(Math.ceil(data?.length / itemsPerPage));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemOffset, itemsPerPage, data])
    
    const handlePageClick = (event) => {
        if(backendPaginated){
            searchParams.set("page", event.selected+1);
            setSearchParams(searchParams);
        } else {
            const newOffset = (event.selected * itemsPerPage) % data?.length;
            setItemOffset(newOffset);
        }
    };
  
    return (
        <div className='flex items-center gap-x-4'>
            {
                showDownload &&
                <button 
                    // disabled={backendPaginated}
                    onClick={onDownloadClick}
                    className='flex gap-x-1 font-semibold items-center border border-[#D0D5DD] bg-[#D0D5DD] text-ee-grey-9 px-2 py-1 font-normal whitespace-nowrap rounded-[6px]'
                >
                    Download CSV
                </button>
            }
            <ReactPaginate
                breakLabel="..."
                nextLabel={
                    <button className='flex gap-x-1 items-center border border-ee-grey-9 text-ee-grey-9 px-2 py-1.5 rounded-[6px]'>
                        <p className='font-semibold'>Next</p>
                        <AiOutlineArrowRight className='w-6 font-semibold'/>
                    </button>
                }
                initialPage={Number(page)-1}
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={pageCount}
                previousLabel={
                    <button className='flex gap-x-1 items-center border border-ee-grey-9 text-ee-grey-9 px-2 py-1.5 rounded-[6px]'>
                        <AiOutlineArrowLeft className='w-6 font-semibold'/>
                        <p className='font-semibold'>Previous</p>
                    </button>
                }
                renderOnZeroPageCount={null}
                containerClassName='flex items-center text-sm gap-1.6 list-none w-full'
                pageLinkClassName='p-2 font-normal cursor-pointer text-ee-black-4 hover:text-ee-black-4'
                pageClassName="w-8 rounded-[4px] flex justify-center hover:bg-[#E5EEFF]"
                previousClassName='mr-auto'
                nextClassName="ml-auto"
                nextLinkClassName='mt-2.5'
                breakLinkClassName="p-2"
                activeClassName='bg-[#E5EEFF]'
                activeLinkClassName='text-ee-primary'
            />
        </div>
    );
}