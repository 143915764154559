import PDFView from "components/PDFView";
import Button from "components/shared/Button";
import React from "react";
import { useState } from "react";
import {
    AreaChart,
    Area,
    XAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
} from "recharts";
import { currencyFormatter, formatBigNumber, generatePDF } from "utils/helpers";


export default function CustomAreaChart({ 
    title, data,
    months=[],
    currencyValues=false,
 }) {
    const [isDownloading, setIsDownloading] = useState(false);

    const maxItemsLength = Math.max(...data.map((item) => item.items.length));

    const formattedData = [];

    for (let i = 0; i < maxItemsLength; i++) {
        const obj = { 
            name: months[i]?.substring(0, 3)
        }
        
        data.forEach((entry) => {
            obj[entry.slug] = entry.items[i] !== undefined ? entry.items[i] : null;
        });
        
        formattedData.push(obj);
    }

    const CustomTooltip = ({ active, payload, label, test }) => {
        if (active && payload && payload.length) {
          return (
            <div className="bg-white shadow-md rounded-lg p-[10px] text-center text-sm">
              <p className="text-[#71717A]">{months.find(v => v.includes(label))}</p>
              {
                data?.map(item=>(
                    <p className="text-[#18181B]">
                        {item.name}: {" "}
                        <span className="font-bold">
                            {
                                currencyValues ?
                                currencyFormatter(payload[0]?.payload[`${item.slug}`], "NGN", 0, true)
                                :
                                formatBigNumber(payload[0]?.payload[`${item.slug}`]||0)
                            }
                        </span>
                    </p>
                ))
              }
            </div>
          );
        }
      
        return null;
    };
    
    return (
        <div className="bg-white rounded-lg p-[40px] shadow-[0px_1.21px_2.41px_0px_#1018280F,0px_1.21px_3.62px_0px_#1018281A] border-[1px] border-[#EAECF0]">
            <Button
                text="12 months"
                type="button"
                className="bg-[#CCDCFF] px-3 py-2.5 mb-[20px] !text-base !text-ee-primary !rounded-md"
            />
            <div className="border-[1px] rounded-lg p-[20px]">
                <div className="grid grid-cols-2">
                    <div>
                        <h3 className="font-medium text-[24px] leading-[27px]">{title}</h3>
                        <Button
                            text="Export PDF"
                            type="button"
                            isLoading={isDownloading}
                            className="bg-white px-2 py-2 mt-[10px] border-[1px] border-[#D4D4D8] font-bold !text-sm !text-black !rounded-md"
                            icon={
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.56271 7.59687V11.6442M9.56271 11.6442L7.33669 9.62053M9.56271 11.6442L11.7887 9.62053M13.2728 15.017H5.85267C5.03307 15.017 4.36865 14.4129 4.36865 13.6679V4.22411C4.36865 3.47902 5.03307 2.875 5.85267 2.875H9.99737C10.1942 2.875 10.3829 2.94607 10.5221 3.07257L14.5394 6.72475C14.6786 6.85125 14.7568 7.02283 14.7568 7.20173V13.6679C14.7568 14.4129 14.0924 15.017 13.2728 15.017Z" stroke="#18181B" stroke-width="1.66952" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            }
                            iconPosition="left"
                            onClick={()=>{
                                if(!isDownloading){
                                    setIsDownloading(true)
                                    generatePDF(
                                        title, 
                                        title,
                                        ()=>{
                                            setIsDownloading(false)
                                        }
                                    )
                                }
                            }}
                        />
                    </div>
                    <div className="flex gap-x-[20px]">
                        {
                            data?.map(item=>(
                                <div key={item.slug} className="flex gap-x-1.5">
                                    <div 
                                        className="w-2.5 h-2.5 rounded-full" 
                                        style={{backgroundColor: item.color}}
                                    />
                                    <div>
                                        <p className="text-sm -mt-[5px]">{item.name}</p>
                                        <p className="text-[20px] font-bold">{formatBigNumber(item.total)}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="mt-7">
                    <ResponsiveContainer width="100%" height={250}>
                        <AreaChart
                            data={formattedData}
                            margin={{
                                top: 0,
                                right: 0,
                                left: 15,
                                bottom: 0,
                            }}
                            padding={{
                                top: 40,
                                right: 0,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid
                                // strokeDasharray="3 3"
                                stroke="rgba(0,0,0, 0.07)"
                                horizontal={true}
                                vertical={false}
                            />
                            <XAxis dataKey="name" tick="#818181" />
                            <Tooltip 
                                content={<CustomTooltip />}
                            />
                            {
                                data?.map(item=>(
                                    <Area 
                                        key={item.slug}
                                        type="monotone"
                                        dataKey={item.slug}
                                        stroke={item.color}
                                        dot={false}
                                        fill="#EEF2FF80"
                                        strokeWidth={2}
                                    />
                                ))
                            }
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <PDFView id={title}>
                <div className="bg-white p-10 min-h-[275mm]">
                    <div className="border-[1px] rounded-lg p-[20px]">
                        <div className="grid grid-cols-2">
                            <h3 className="font-medium text-[24px] leading-[27px]">{title}</h3>
                            <div className="flex gap-x-[20px]">
                                {
                                    data?.map(item=>(
                                        <div key={item.slug} className="flex gap-x-1.5">
                                            <div 
                                                className="w-2.5 h-2.5 rounded-full" 
                                                style={{backgroundColor: item.color}}
                                            />
                                            <div>
                                                <p className="text-sm -mt-[5px]">{item.name}</p>
                                                <p className="text-[20px] font-bold">{formatBigNumber(item.total)}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="mt-7">
                            <ResponsiveContainer width="100%" height={250}>
                                <AreaChart
                                    data={formattedData}
                                    margin={{
                                        top: 0,
                                        right: 0,
                                        left: 15,
                                        bottom: 0,
                                    }}
                                    padding={{
                                        top: 40,
                                        right: 0,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid
                                        // strokeDasharray="3 3"
                                        stroke="rgba(0,0,0, 0.07)"
                                        horizontal={true}
                                        vertical={false}
                                    />
                                    <XAxis dataKey="name" tick="#818181" />
                                    <Tooltip 
                                        content={<CustomTooltip />}
                                    />
                                    {
                                        data?.map(item=>(
                                            <Area 
                                                key={item.slug}
                                                type="monotone"
                                                dataKey={item.slug}
                                                stroke={item.color}
                                                dot={false}
                                                fill="#EEF2FF80"
                                                strokeWidth={2}
                                            />
                                        ))
                                    }
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            </PDFView>
        </div>
    );
}