import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import SelectInput from '../../../components/Form/SelectInput';
import Loader from '../../../components/shared/Loader';
import { getMetrics } from 'services/metrics.service';
import apiIcon from "../../../images/svg/box/tech.svg";

const Metrics = () => {
    const [query, setQuery] = useState('this_month')
    const [queryVal, setQueryVal] = useState('This month')
    // const [searchTerm, setSearchTerm] = useState('');
    // const [filteredData, setFilteredData] = useState([]);
    const [startDateee, setStartDateee] = useState("");
    const [endDateee, setEndDateee] = useState("");
    const [customStartDate, setCustomStartDate] = useState("");
    const [customEndDate, setCustomEndDatee] = useState("");
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const handleClose = () => setShow(false);

    const [boxData, setBoxData] = useState({
        boxFor: "metrics",
        item: []
    });

    function formatPickerDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    useEffect(() => {
        setIsLoading(true)
        setCustomStartDate(`${new Date(startDateee)?.getDate()} ${months[new Date(startDateee)?.getMonth()]?.substring(0, 3)} ${new Date(endDateee)?.getFullYear()}`)
        setCustomEndDatee(`${new Date(endDateee)?.getDate()} ${months[new Date(endDateee)?.getMonth()]?.substring(0, 3)} ${new Date(endDateee)?.getFullYear()}`)
        getMetrics(query)
            .then(response => response.data)
            .then((res) => {
                if (res.status === 'success') {
                    setBoxData({
                        ...boxData,
                        item: res.data
                    })
                    setIsLoading(false)
                } else {
                    toast.error("Something went wrong, please try again!!!")
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                if (err.response) {
                    toast.error(err.response.data.message);
                    setIsLoading(false)
                } else {
                    toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                    setIsLoading(false)
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    const handleSelectChange = (name, val) => {
        if
            // (val === 'All'){
            //   setQuery('all')
            // }else if
            (val === 'Last 7 days') {
            setQuery('last_seven_days')
            setQueryVal('Last 7 days')
        } else if (val === 'This Month') {
            setQuery('this_month')
            setQueryVal('This Month')
        } else if (val === 'Last Month') {
            setQuery('last_month')
            setQueryVal('Last Month')
        } else if (val === 'Last 3 Months') {
            setQuery('last_three_months')
            setQueryVal('Last 3 Months')
        } else if (val === 'Last 6 Months') {
            setQuery('last_six_months')
            setQueryVal('Last 6 Months')
        } else if (val === 'Last 12 Months') {
            setQuery('last_twelve_months')
            setQueryVal('Last 12 Months')
        } else if (val === 'Custom') {
            setQuery('custom')
            setQueryVal('Custom')
            setShow(true)
        }
    }


    return (
        <div className='home_main'>

            <Modal show={show} onHide={handleClose}>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => {
                        setStartDateee(formatPickerDate(item.selection.startDate))
                        setEndDateee(formatPickerDate(item.selection.endDate))
                        setState([item.selection])
                    }
                    }
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                />
                <button
                    style={{
                        color: "white",
                        padding: "7px 25px",
                        textAlign: "center",
                        border: "1px solid #0046E6",
                        background: "#0046E6"
                    }}
                    onClick={() => {
                        handleClose();
                        setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                        setQueryVal(`${customStartDate} - ${customEndDate}`)
                    }}
                >Proceed</button>
            </Modal>

            <div className='w-[150px] block ml-auto'>
                <SelectInput
                    placeholder={"This Month"}
                    options={["Last 7 days", "This Month", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Custom"]}
                    selectChange={(item) => handleSelectChange("Query", item)}
                />
            </div>
            {
                isLoading ?
                    <Loader /> :
                    <DataBox boxData={boxData} query={queryVal} />
            }




        </div>
    )
}

export default Metrics


function DataBox({ boxData, query }) {
    const data = Object.entries(boxData.item);
    return (
        <ul className="flex gap-4 text-sm flex-wrap">
            {data.map(([title, value], index) => (
                <Card key={index} title={title} value={value} />
            ))}
        </ul>
    );
}

function Card({ title, value }) {
    const title0 = normalizeTitle(title);
    let value0 = value;
    if (title0.includes("Rate")) {
        value0 = `${value.toFixed(2)}%`;
    }
    return (
        <li className="flex items-center gap-2 w-[250px] p-4 rounded-lg shadow-[2px_2px_20px_#eee]">
            <div className="flex items-center justify-center w-12 h-12 bg-ee-grey-10 rounded-full text-ee-blue-4 font-semibold text-3xl p-2">
                <img src={apiIcon} alt="icon" width={30} />
            </div>
            <div>
                <p className="font-bold text-lg">{value0}</p>
                <p>{title0}</p>
            </div>
        </li>
    );
}


// map title from API to title in the UI
function normalizeTitle(title) {
    const data = {
        "activeBusinesses": "Active Businesses",
        "churnRate": "Churn Rate",
        "churnedBusinesses": "Churned Businesses",
        "dailyActiveUsers": "Daily Active Users",
        "inactiveBusinesses": "Inactive Businesses",
        "monthlyActiveUsers": "Monthly Active Users",
        "retentionRate": "Retention Rate",
        "totalBusinesses": "Total Businesses",
        "weeklyActiveUsers": "Weekly Active Users",
        "adoptionRate": "Adoption Rate",
    }

    return data[title] || title;
}
