import { APIKit, loadToken } from "services";

const timeout = 15000;


export const BusinessService = {
    getTopBusinesses: (args) => {
        return APIKit(timeout).get(`eagleeyeauth/business/business-activity`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    revenueActivity: () => {
        return APIKit(timeout).get(`eagleeyeauth/business/revenue-graph`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    businessGrowth: () => {
        return APIKit(timeout).get(`eagleeyeauth/business/growth-graph`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    apiActivity: () => {
        return APIKit(timeout).get(`eagleeyeauth/business/api-activity`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    getBusinessData: (args) => {
        return APIKit(timeout).get(`eagleeyeauth/business/stats/get-business-data`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    getAccountData: (args) => {
        return APIKit(timeout).get(`eagleeyeauth/business/get-wallet-data`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    getApiData: (args) => {
        return APIKit(timeout).get(`eagleeyeauth/business/get-api-data`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    getRevenueData: (args) => {
        return APIKit(90000).get(`eagleeyeauth/business/get-revenue-data`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    businessStats: (type) => {
        return APIKit(timeout).get(`eagleeyeauth/business/business-stats?type=${type}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    getBusinessByStatus: (status) => {
        return APIKit(timeout).get(`eagleeyeauth/business/business-status?status=${status}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    getDashboardOverviewData: (args) => {
        return APIKit(timeout).get(`eagleeyeauth/business/overview-data`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            },
            params: {
                ...args,
            }
        })
    },

    getApiTrends: () => {
        return APIKit(timeout).get(`eagleeyeauth/business/api-graph`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },
}