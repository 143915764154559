import axios from 'axios';

let token = '';
let userRole = JSON.parse(sessionStorage.getItem('eagleEye_details'))?.adminRole
let adminName = JSON.parse(sessionStorage.getItem('adminName'))

const baseUrl = process.env.REACT_APP_ENVIRONMENT === "production"
        ?process.env.REACT_APP_PRODUCTION
        :process.env.REACT_APP_LOCAL

let APITest = axios.create({
    baseURL: baseUrl,
    timeout: 100000, // timeout can be increased
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'role': userRole,
        'name': adminName
    },
});

const loadToken = () => {
    try {
        token = JSON.parse(sessionStorage.getItem('eagleEye_details')).token;
    } catch (err) {
        //err
    }
}

export const getApiCallStats = (apiName, timePeriod) => {
    loadToken();
    return APITest.get(`eagleeyeauth/business/apicall-activity?type=${timePeriod}&name=${apiName}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const getApiCallStatsCumulative = (timePeriod) => {
    loadToken();
    return APITest.get(`eagleeyeauth/business/apicall-activity/cumulative?type=${timePeriod}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

