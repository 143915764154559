import clsx from "clsx";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export default function Tabs ({
    tabs, 
    useAsLink=false,
    clearSearch, // optional
    containerClass="",
    variant="line", // or filled
    tabName="tab",
}) {
    const location = useLocation();
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(()=>{
        const initialTab = searchParams.get(tabName)
        if(!initialTab && !useAsLink){
            searchParams.set(tabName, tabs[0].key);
            setSearchParams(searchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changePath = (value) => {
        if(useAsLink){
            navigate(value)
        } else {
            if(clearSearch){
                searchParams.set('search', '');
            }
            searchParams.set(tabName, value);
            setSearchParams(searchParams);
        }
    }
    
    return (
        <div 
            className={clsx(
                "hide-scrollBar flex overflow-x-scroll box-border",
                containerClass,
                variant==="line" && "border-b-[1px] border-solid border-[#B5B5B5]",
            )}
        >
            {
                tabs.map(item=>(
                    <p
                        className={clsx(
                            "mb-0 whitespace-nowrap pb-2.5 cursor-pointer",
                            (location?.pathname===item?.key || searchParams.get(tabName)===item?.key) && "border-b-[3px] border-solid border-ee-primary",
                            variant==="filled" ? "min-w-[100px] text-[#667085] font-semibold px-3 text-center pt-2.5 mt-2.5":"mr-[30px] pt-[20px] text-black",
                            (variant==="filled" && (location?.pathname===item?.key || searchParams.get(tabName)===item?.key)) && "bg-[#E5EEFF]",
                        )}
                        onClick={()=>changePath(item.key)}
                    >
                        {item.label}
                    </p>
                ))
            }
        </div>
    )
}